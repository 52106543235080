import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { LuAlertCircle } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

function ResetPasswordInvalidLink() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <Box
        maxW="lg"
        mx="auto"
        mt="8"
        p="6"
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
        textAlign="center"
      >
        <VStack spacing="4">
          <Flex align="center">
            <LuAlertCircle size="36" /> {/* Set color directly or use color prop */}
            <Text fontSize="2xl" fontWeight="bold" ml={2}>
              {t("reset_password.invalid_link.title")}
            </Text>
          </Flex>
          <Text fontSize="md" color="gray.600">
            {t("reset_password.invalid_link.message")}
          </Text>
          <Button
            backgroundColor="gray.600"
            color="white"
            _hover={{ backgroundColor: "gray.700" }}
            onClick={goToForgotPassword}
          >
            {t("reset_password.invalid_link.new_link_button")}
          </Button>
        </VStack>
      </Box>
    </>
  );
}

export default ResetPasswordInvalidLink;
