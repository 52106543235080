import { Button, Input, InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";
import { forwardRef, useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";

const PasswordInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input pr="4rem" type={show ? "text" : "password"} {...props} ref={ref} />
      <InputRightElement width="4rem">
        <Button
          h="1.75rem"
          size="lg"
          onClick={handleClick}
          backgroundColor="transparent"
          padding={0}
        >
          {show ? <LuEyeOff /> : <LuEye />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
