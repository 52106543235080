import { Box, Heading } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import logoIcon from "../../assets/logo-tct.png";

type LogoProps = {
  zIndex?: number;
};

const Logo = ({ zIndex }: LogoProps): ReactElement => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <Box display="flex" alignItems="center" zIndex={zIndex} cursor="pointer" onClick={goHome}>
      <img src={logoIcon} alt="Logo" width="40" height="40" />
      <Heading as="h2" size="lg" ml="1rem" color="white">
        Tennis Club Trebaseleghe
      </Heading>
    </Box>
  );
};

export default Logo;
