import { useEffect } from "react";
import api from "../api";
import { ACCESS_TOKEN_KEY } from "../constants";
import useAuthStore from "../store/auth";

/**
 * a hook that check if a stored access token is valid and in case set the user in the global state
 * @returns {void}
 */
const useInitUser = (): void => {
  const [setUser, setIsAuthenticated] = useAuthStore(state => [
    state.setUser,
    state.setIsAuthenticated,
  ]);

  useEffect(() => {
    const initUser = async () => {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (!accessToken) {
        setIsAuthenticated(false);
        return;
      }

      const user = await api.getCurrentUser();
      if (!user) {
        setIsAuthenticated(false);
        return;
      }
      setUser(user);
      setIsAuthenticated(true);
    };

    initUser();
  }, []);
};

export default useInitUser;
