import { Flex } from "@chakra-ui/react";
import Logo from "../logo/logo";

const HeaderCompact = () => (
  <Flex as="nav" bg="gray.800" py={4} justifyContent="center">
    <Logo />
  </Flex>
);

export default HeaderCompact;
