import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { LuChevronDown, LuLogOut, LuUser } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/auth";
import LoginModal from "../auth/login/login-modal";
import Logo from "../logo/logo";
import useLogout from "./hooks/useLogout";

const Header = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isOpen: isLoginModalOpen,
    onOpen: onLoginModalOpen,
    onClose: onLoginModalClose,
  } = useDisclosure();

  const [user] = useAuthStore(state => [state.user]);
  const { logout } = useLogout();

  const goToSignup = () => {
    navigate("/signup");
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <>
      <Flex as="nav" align="center" justify="space-between" padding="1rem" bg="gray.800">
        {/* logo */}
        <Flex align="center">
          <Logo />
        </Flex>
        {/* action buttons */}
        {!user && (
          <Box>
            <Button colorScheme="twitter" mr={4} onClick={onLoginModalOpen}>
              {t("navbar.login")}
            </Button>
            <Button colorScheme="green" onClick={goToSignup}>
              {t("navbar.signup")}
            </Button>
            {/* TODO: add instruction/tutorial button */}
          </Box>
        )}

        {/* User profile */}
        {user && (
          <Flex
            borderLeft="2px solid white"
            flexDirection="row"
            alignItems="center"
            paddingLeft={4}
          >
            <Menu>
              <MenuButton
                as={Button}
                backgroundColor="transparent"
                _hover={{ backgroundColor: "gray.700" }}
                _active={{ backgroundColor: "gray.700" }}
              >
                <Flex alignItems="center">
                  <LuUser size="24" color="white" />
                  <Text marginLeft="2" marginRight="2" fontSize="xl" color="white">
                    {user.name}
                  </Text>
                  <LuChevronDown size="24" color="white" />
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={goToProfile}>
                  <LuUser /> &nbsp; {t("navbar.profile")}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={logout}>
                  <LuLogOut /> &nbsp; {t("navbar.logout")}
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
      </Flex>

      {/* login modal */}
      <LoginModal isOpen={isLoginModalOpen} onClose={onLoginModalClose} />
    </>
  );
};

export default Header;
