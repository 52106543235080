import { Box, Container } from "@chakra-ui/react";
import HeaderCompact from "../components/header/header-compact";
import EditPasswordForm from "../components/profile/edit-password-form";
import EditProfileForm from "../components/profile/edit-profile-form";

const ProfilePage = () => (
  <Box height="100vh" display="flex" flexDirection="column">
    <HeaderCompact />

    <Box
      flex="1"
      overflowY="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="gray.50"
      pt={8}
    >
      <Container maxW="lg" height="100%">
        <Box p={8} borderWidth={1} borderRadius="lg" boxShadow="lg" bg="white">
          <EditProfileForm />
          <EditPasswordForm />
        </Box>
      </Container>
    </Box>
  </Box>
);

export default ProfilePage;
