import { useEffect, useState } from "react";
import api from "../../../api";
import { HttpError, Price } from "../../../api/types";

/**
 * Custom hook to fetch prices from backend.
 * @returns {object} - Contains prices, loading state, error state, and refetch function.
 */
function useFetchPrices() {
  const [prices, setPrices] = useState<Price[] | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchPrices = async () => {
    setLoading(true);
    setError(null);

    try {
      const prices = await api.getPrices();

      if (api.error) {
        setError(api.error);
      }

      setPrices(prices);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  return {
    prices,
    loading,
    error,
    refetchPrices: fetchPrices,
  };
}

export default useFetchPrices;
