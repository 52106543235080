import { Flex, Text } from "@chakra-ui/react";

type ReceiptPriceDescriptionProps = {
  description: string;
};

const ReceiptPriceDescription = ({ description }: ReceiptPriceDescriptionProps) => (
  <Flex width="4xl" mx="auto" padding={2}>
    <Text fontWeight="bold">{description}</Text>
  </Flex>
);

export default ReceiptPriceDescription;
