import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PASSWORD_MIN_LENGTH } from "../constants";

type ErrorType = "passwordTooShort" | "passwordMismatch";

type ValidationError = {
  type: ErrorType;
  message: string;
};

/**
 * a hook that validate password
 * @returns {void}
 */
const useValidatePassword = () => {
  const [error, setError] = useState<ValidationError | undefined>(undefined);
  const { t } = useTranslation();

  const isPasswordValid = (password: string, confirmPassword?: string): boolean => {
    setError(undefined);

    // check password lenght and match with confirmation
    if (password.length < PASSWORD_MIN_LENGTH) {
      setError({ type: "passwordTooShort", message: t("password.errors.password_too_short") });
      return false;
    }
    if (confirmPassword && password !== confirmPassword) {
      setError({ type: "passwordMismatch", message: t("password.errors.password_mismatch") });
      return false;
    }

    return true;
  };

  const resetPasswordError = () => {
    setError(undefined);
  };

  return { isPasswordValid, passwordError: error, resetPasswordError };
};

export default useValidatePassword;
