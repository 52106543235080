// eslint-disable-next-line import/prefer-default-export
export const parseToIntOrNull = (value: string | null | undefined) => {
  if (!value) {
    return null;
  }

  const number = parseInt(value, 10);
  if (isNaN(number)) {
    return null;
  }
  return number;
};
