import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; // Assuming you use react-router for navigation
import usePaymentStore from "../../store/payment";

const BookingPaymentBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [bookingsToPay, clearBookingsToPay] = usePaymentStore(state => [
    state.bookingsToPay,
    state.clearBookingsToPay,
  ]);

  const handleClearHours = () => {
    clearBookingsToPay();
  };

  const handleProceedToPayment = () => {
    navigate("/receipt");
  };

  if (bookingsToPay.length > 0) {
    return (
      <Box mt={4} p={4}>
        <Flex justify="flex-end" align="center">
          <Stack direction="row" spacing={4}>
            <Button colorScheme="blue" variant="outline" onClick={handleClearHours}>
              {t("booking.payment_bar.cancel_selection")}
            </Button>
            <Button
              colorScheme="green"
              onClick={handleProceedToPayment}
              disabled={bookingsToPay.length === 0}
            >
              {t("booking.payment_bar.proceed_to_payment")} ({bookingsToPay.length})
            </Button>
          </Stack>
        </Flex>
      </Box>
    );
  }

  return null;
};

export default BookingPaymentBar;
