import { useEffect, useState } from "react";
import api from "../../../api";
import { HttpError, ReceiptPreview } from "../../../api/types";

/**
 * Custom hook to fetch the receipt preview of the provided bookings from backend.
 * @returns {object} - Contains receipt preview, loading state, error state, and refetch function.
 */
function useFetchReceiptPreview(bookingIds: number[]) {
  const [receiptPreview, setReceiptPreview] = useState<ReceiptPreview | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchReceiptPreview = async (bookingIds: number[]) => {
    setLoading(true);
    setError(null);

    if (!bookingIds.length) {
      setLoading(false);
      return;
    }

    try {
      const preview = await api.getReceiptPreview(bookingIds);

      if (api.error) {
        setError(api.error);
      }

      setReceiptPreview(preview);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReceiptPreview(bookingIds);
  }, []);

  return {
    receiptPreview,
    loading,
    error,
    refetchReceiptPreview: fetchReceiptPreview,
  };
}

export default useFetchReceiptPreview;
