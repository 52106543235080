import { ACCESS_TOKEN_KEY } from "../../../constants";
import useAuthStore from "../../../store/auth";
import usePaymentStore from "../../../store/payment";

/**
 * Custom hook to log out a user.
 * @returns {object} - logout function.
 */
function useLogout() {
  const [setUser] = useAuthStore(state => [state.setUser]);
  const [clearBookingsToPay] = usePaymentStore(state => [state.clearBookingsToPay]);

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    setUser(null);
    clearBookingsToPay();
  };

  return { logout };
}

export default useLogout;
