import { Box, Container } from "@chakra-ui/react";
import { useState } from "react";
import ConfirmEmail from "../../components/auth/signup/confirm-email";
import SignupForm from "../../components/auth/signup/signup-form";
import HeaderCompact from "../../components/header/header-compact";

const SignupPage = () => {
  const [userId, setUserId] = useState<number | null>(null);

  const onCompleted = (userId: number) => {
    setUserId(userId);
  };

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <HeaderCompact />

      {/* Signup Form */}
      <Box
        flex="1"
        overflowY="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="gray.50"
        pt={8}
      >
        <Container maxW="lg" height="100%">
          {!userId && <SignupForm onCompleted={onCompleted} />}
          {userId && <ConfirmEmail userId={userId} />}
        </Container>
      </Box>
    </Box>
  );
};

export default SignupPage;
