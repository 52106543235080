import { Button, Container, Heading, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import useShowToast from "../../../hooks/useShowToast";

type ConfirmEmailProps = {
  userId: number;
};

const ConfirmEmail = ({ userId }: ConfirmEmailProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { successToast, errorToast } = useShowToast();

  const handleResendEmail = async () => {
    await api.sendActivationLink(userId);
    if (api.error) {
      errorToast({
        title: t("confirmEmail.resend.error_title"),
        description: t("confirmEmail.resend.error_message"),
      });
      return;
    }

    successToast({
      title: t("confirmEmail.resend.success_title"),
      description: t("confirmEmail.resend.success_message"),
    });
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Container maxW="ld" textAlign="center">
      <VStack spacing={6}>
        <Heading as="h1" size="lg">
          {t("confirmEmail.title")}
        </Heading>
        <Text fontSize="lg" className="linebreak">
          {t("confirmEmail.message")}
        </Text>
        <Text fontSize="sm" color="gray.600">
          {t("confirmEmail.email_not_received_message")}
        </Text>
        <Button colorScheme="green" size="lg" onClick={handleResendEmail}>
          {t("confirmEmail.resend_email")}
        </Button>
        <Button variant="link" size="lg" colorScheme="green" onClick={handleGoHome}>
          {t("confirmEmail.go_home")}
        </Button>
      </VStack>
    </Container>
  );
};

export default ConfirmEmail;
