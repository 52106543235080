import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { PayBookingsRequest } from "../../api/types";
import useShowToast from "../../hooks/useShowToast";
import usePaymentStore from "../../store/payment";
import { ReceiptData } from "./types";

type ReceiptActionsProps = {
  data: ReceiptData;
  mode: "edit" | "view";
};

const ReceiptActions = ({ data, mode }: ReceiptActionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { successToast } = useShowToast();
  const [bookingsToPay, clearBookingsToPay] = usePaymentStore(state => [
    state.bookingsToPay,
    state.clearBookingsToPay,
  ]);

  const goToBooking = () => {
    navigate("/booking");
  };

  const goToReceipt = (receiptId: number) => {
    navigate(`/receipt/${receiptId}`);
  };

  const print = () => {
    window.print();
  };

  const showSuccessToast = () => {
    successToast({
      title: t("receipt.pay.success_title"),
      description: t("receipt.pay.success_description"),
    });
  };

  const payBookings = async () => {
    const request: PayBookingsRequest = {
      booking_ids: bookingsToPay.map(booking => booking.id),
      name: data.name,
      item_price: data.itemPrice,
      item_count: data.itemCount,
    };
    const response = await api.payBookings(request);
    if (api.error || !response) {
      console.log("Error paying bookings", api.error);
      return;
    }

    clearBookingsToPay();
    showSuccessToast();
    goToReceipt(response.receipt_id);
  };

  return (
    <Box mt={4} p={4} className="no-print">
      <Flex justify="center" align="center">
        {/* Buttons */}
        <Stack direction="row" spacing={4}>
          <Button colorScheme="blue" variant="outline" onClick={goToBooking}>
            {t("receipt.action.back_to_booking")}
          </Button>
          {mode === "edit" ? (
            <Button colorScheme="blue" onClick={payBookings}>
              {t("receipt.action.confirm")}
            </Button>
          ) : (
            <Button colorScheme="blue" onClick={print}>
              {t("receipt.action.print")}
            </Button>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

export default ReceiptActions;
