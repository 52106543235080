import { Box, Link, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CONTACT_EMAIL, CONTACT_PHONE, CONTACT_WEB } from "../../constants";

const Footer = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box mt={8} p={4} bg="gray.700" textAlign="center" mb="-100px" color="white">
      <Text fontSize="md" fontWeight="bold">
        {t("footer.contact")}
      </Text>
      <Text fontSize="sm">
        <Text as="b"> {t("footer.email")}:</Text>{" "}
        <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link> |{" "}
        <Text as="b">{t("footer.phone")}:</Text>{" "}
        <Link href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</Link> |{" "}
        <Text as="b">{t("footer.web")}:</Text>{" "}
        <Link href={`https://${CONTACT_WEB}`}>{CONTACT_WEB}</Link>
      </Text>

      <Text fontSize="sm">
        Powered by{" "}
        <Link href="https://nibbol.it" fontWeight="bold">
          Nibbol
        </Link>
      </Text>
    </Box>
  );
};

export default Footer;
