import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import { ResetPasswordRequest } from "../../../api/types";
import useShowToast from "../../../hooks/useShowToast";
import useValidatePassword from "../../../hooks/useValidatePassword";
import PasswordInput from "../../ui/password-input/password-input";

type ResetPasswordFormData = {
  password: string;
  confirmPassword: string;
};

type ResetPasswordFormProps = {
  code: string;
  username: string;
};

function ResetPasswordForm({ code, username }: ResetPasswordFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<ResetPasswordFormData>();
  const { errorToast } = useShowToast();
  const { isPasswordValid, passwordError, resetPasswordError } = useValidatePassword();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data: ResetPasswordFormData) => {
    setSuccess(false);

    // validate password
    if (!isPasswordValid(data.password, data.confirmPassword)) {
      return;
    }

    const request: ResetPasswordRequest = { username, reset_code: code, password: data.password };
    await api.resetPassword(request);

    if (api.error) {
      console.error(api.error);
      errorToast({
        title: t("reset_password.error.title"),
        description: t("reset_password.error.message"),
      });
      return;
    }

    setSuccess(true);
    setTimeout(() => navigate("/"), 5000);
  };

  return (
    <Box
      maxW="md"
      mx="auto"
      mt="8"
      p={8}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      bg="white"
    >
      <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb="4">
        {t("reset_password.title")}
      </Text>
      <Text fontSize="sm" color="gray.600" textAlign="center" mb="6">
        {t("reset_password.message")}
      </Text>

      <form
        id="reset-password-form"
        onSubmit={handleSubmit(onSubmit)}
        onChange={resetPasswordError}
      >
        <VStack spacing="4">
          <FormControl id="newPassword" isRequired>
            <FormLabel>{t("reset_password.new_password")}</FormLabel>
            <PasswordInput
              placeholder={t("reset_password.placeholder.new_password")}
              {...register("password", { required: "password is required" })}
            />
          </FormControl>

          <FormControl id="confirmPassword" isRequired>
            <FormLabel>{t("reset_password.confirm_new_password")}</FormLabel>
            <PasswordInput
              placeholder={t("reset_password.placeholder.confirm_new_password")}
              {...register("confirmPassword", { required: "confirm password is required" })}
            />
          </FormControl>

          {/* errors */}
          {passwordError && (
            <Alert status="error" mb="0.5rem">
              <AlertIcon />
              <AlertDescription>{passwordError.message}</AlertDescription>
            </Alert>
          )}

          {!success && (
            <Button
              backgroundColor="gray.600"
              color="white"
              _hover={{ backgroundColor: "gray.700" }}
              width="full"
              type="submit"
            >
              {t("reset_password.reset_password")}
            </Button>
          )}

          {/* success */}
          {success && (
            <Alert status="success" mb="0.5rem">
              <AlertIcon />
              <AlertDescription>{t("reset_password.success")}</AlertDescription>
            </Alert>
          )}
        </VStack>
      </form>
    </Box>
  );
}

export default ResetPasswordForm;
