import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Price } from "../api/types";
import PriceList from "../components/price-list/price-list";
import useFetchReceiptPreview from "../components/receipt/hooks/useFetchReceiptPreview";
import ReceiptActions from "../components/receipt/receipt-actions";
import ReceiptPreview from "../components/receipt/receipt-preview";
import ReceiptPriceDescription from "../components/receipt/receipt-price-description";
import { ReceiptData } from "../components/receipt/types";
import usePaymentStore from "../store/payment";

const ReceiptGeneratePage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<ReceiptData | undefined>(undefined);
  const [description, setDescription] = useState<string>("");
  const [bookingsToPay] = usePaymentStore(state => [state.bookingsToPay]);
  const { receiptPreview } = useFetchReceiptPreview(bookingsToPay.map(b => b.id));

  const onSelect = (price: Price) => {
    if (!data) {
      return;
    }
    setData({ ...data, itemPrice: price.value });
    setDescription(price.description);
  };

  useEffect(() => {
    if (bookingsToPay.length === 0) {
      navigate("/booking");
    }

    if (receiptPreview) {
      setData({
        number: receiptPreview.number,
        name: receiptPreview.name,
        itemCount: receiptPreview.item_count,
        itemPrice: receiptPreview.item_price,
      });
      setDescription(receiptPreview.item_description);
    }
  }, [receiptPreview, bookingsToPay, navigate]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ReceiptPreview data={data} onChange={setData} />
      <ReceiptPriceDescription description={description} />
      <ReceiptActions data={data} mode="edit" />
      <PriceList onSelect={onSelect} />
    </>
  );
};

export default ReceiptGeneratePage;
