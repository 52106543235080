import { useState } from "react";
import api from "../../../../api";
import { HttpError } from "../../../../api/types";

/**
 * Custom hook to check if the password reset code is still valid.
 * @returns {object} - Contains isResetCodeValid, loading state, error state
 */
function useValidateResetCode() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const isResetCodeValid = async (username: string, code: string): Promise<boolean> => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.validateResetCode(username, code);

      if (api.error) {
        setError(api.error);
        return false;
      }

      if (!response) {
        return false;
      }

      return response?.is_valid;
    } finally {
      setLoading(false);
    }
  };

  return {
    isResetCodeValid,
    loading,
    error,
  };
}

export default useValidateResetCode;
