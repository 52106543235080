import { Button, FormControl, FormLabel, Heading, Input, VStack } from "@chakra-ui/react";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api from "../../api";
import { UpdateUserRequest } from "../../api/types";
import useShowToast from "../../hooks/useShowToast";
import useAuthStore from "../../store/auth";

type EditProfileFormData = {
  name: string;
  surname: string;
  email: string;
  phone: string;
};

const EditProfileForm = (): ReactElement => {
  const { t } = useTranslation();
  const [user, setUser] = useAuthStore(state => [state.user, state.setUser]);
  const { register, handleSubmit, reset } = useForm<EditProfileFormData>();
  const { successToast, errorToast } = useShowToast();

  const onSubmit = async (data: EditProfileFormData) => {
    // update user
    const request: UpdateUserRequest = { ...data };
    const response = await api.updateUser(request);
    if (!response || api.error) {
      errorToast({
        title: t("profile.profile_update_error_title"),
        description: t("profile.profile_update_error_message"),
      });
      return;
    }

    setUser(response);
    successToast({
      title: t("profile.profile_update_success_title"),
      description: t("profile.profile_update_success_message"),
    });
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    // populate form with user values
    reset({
      name: user.name,
      surname: user.surname,
      email: user.email,
      phone: user.phone,
    });
  }, [reset, user]);

  return (
    <form id="edit-profile-form" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4} align="flex-start">
        <Heading as="h1" size="lg" mb={4}>
          {t("profile.title")}
        </Heading>

        <FormControl isRequired>
          <FormLabel>{t("profile.name")}</FormLabel>
          <Input
            placeholder={t("profile.placeholder.name")}
            id="name"
            {...register("name", { required: "name is required" })}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{t("profile.surname")}</FormLabel>
          <Input
            placeholder={t("profile.placeholder.surname")}
            id="surname"
            {...register("surname", { required: "surname is required" })}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{t("profile.email")}</FormLabel>
          <Input
            type="email"
            placeholder={t("profile.placeholder.email")}
            id="email"
            {...register("email", { required: "email is required" })}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>{t("profile.phone")}</FormLabel>
          <Input
            type="tel"
            placeholder={t("profile.placeholder.phone")}
            id="phone"
            {...register("phone", { required: "phone is required" })}
          />
        </FormControl>

        <Button
          colorScheme="blue"
          size="lg"
          width="full"
          mt={4}
          alignSelf="center"
          form="edit-profile-form"
          type="submit"
        >
          {t("profile.update_profile_button")}
        </Button>
      </VStack>
    </form>
  );
};

export default EditProfileForm;
