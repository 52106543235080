import { Box, Divider, Flex, HStack, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./receipt-preview.css";
import { ReceiptData } from "./types";

type ReceiptPreviewProps = {
  data: ReceiptData;
  onChange?: (data: ReceiptData) => void;
  readonly?: boolean;
};

const ReceiptPreview = ({ data, onChange = () => {}, readonly = false }: ReceiptPreviewProps) => {
  const { t } = useTranslation();
  const [receiptPreviewData, setReceiptPreviewData] = useState<ReceiptData>(data);
  const totalToPay = receiptPreviewData.itemCount * receiptPreviewData.itemPrice;

  const updateReceiptPreviewData = (field: keyof ReceiptData, value: string | number) => {
    const updatedData = { ...receiptPreviewData, [field]: value };
    setReceiptPreviewData(updatedData);
    onChange(updatedData);
  };

  useEffect(() => {
    setReceiptPreviewData(data);
  }, [data]);

  return (
    <Flex
      borderRadius="md"
      border="2px solid"
      borderColor="gray.300"
      width="4xl"
      mx="auto"
      mt={8}
      className="receipt-container"
    >
      {/* Company receipt */}
      <Box p={6} width="40%" borderRight="2px solid" borderColor="gray.300">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          {t("receipt.preview.receipt")}
        </Text>
        <Divider />

        {/* numero ricevuta */}
        <Box mt={4}>
          <Text fontSize="md" color="gray.500">
            {t("receipt.preview.number")}
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {receiptPreviewData.number}
          </Text>
        </Box>

        {/* Nome utente */}
        <Box mt={4}>
          <Text fontSize="md" color="gray.500">
            {t("receipt.preview.name")}
          </Text>
          {readonly ? (
            <Text fontSize="lg" fontWeight="bold">
              {receiptPreviewData.name}
            </Text>
          ) : (
            <Input
              type="text"
              value={receiptPreviewData.name}
              onChange={e => updateReceiptPreviewData("name", e.target.value)}
              width="100%"
              height="auto"
              padding={0}
              pl={1}
              fontWeight="bold"
            />
          )}
        </Box>

        {/* Costo ore */}
        <Flex mt={4} justifyContent="space-between">
          <Box>
            <Text fontSize="md" color="gray.500">
              {t("receipt.preview.to_pay")}
            </Text>
            <HStack>
              {readonly ? (
                <Text fontSize="lg" fontWeight="bold">
                  {receiptPreviewData.itemCount}
                </Text>
              ) : (
                <Input
                  type="text"
                  value={receiptPreviewData.itemCount}
                  onChange={e =>
                    updateReceiptPreviewData("itemCount", parseFloat(e.target.value) || 0)
                  }
                  maxW="40%"
                  height="auto"
                  padding={0}
                  pl={1}
                  pr={1}
                  fontWeight="bold"
                />
              )}
              <Text fontSize="lg" fontWeight="bold" className="no-wrap">
                {t("receipt.preview.tennis_hours")}
              </Text>
            </HStack>
          </Box>

          <Box textAlign="right">
            <Text fontSize="md" color="gray.500">
              {t("receipt.preview.item_price")}
            </Text>

            {readonly ? (
              <Text fontSize="lg" fontWeight="bold">
                €{receiptPreviewData.itemPrice.toFixed(2)}
              </Text>
            ) : (
              <>
                <Text fontSize="lg" fontWeight="bold" as="span" mr={1}>
                  €
                </Text>
                <Input
                  type="number"
                  step=".01"
                  value={receiptPreviewData.itemPrice}
                  onChange={e =>
                    updateReceiptPreviewData("itemPrice", parseFloat(e.target.value) || 0)
                  }
                  maxW="60%"
                  height="auto"
                  padding={0}
                  pl={1}
                  pr={1}
                  fontWeight="bold"
                />
              </>
            )}
          </Box>
        </Flex>
        <Divider mt={4} />

        {/* Totale da pagare */}
        <Flex justifyContent="space-between" mt={4}>
          <Text fontSize="md" color="gray.500">
            {t("receipt.preview.total")}
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            €{totalToPay.toFixed(2)}
          </Text>
        </Flex>
      </Box>

      {/* Customer receipt */}
      <Box p={6} width="60%">
        {/* Title */}
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          {t("receipt.preview.receipt")}
        </Text>
        <Divider />

        {/* numero ricevuta */}
        <Box mt={4}>
          <Text fontSize="md" color="gray.500">
            {t("receipt.preview.number")}
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {receiptPreviewData.number}
          </Text>
        </Box>

        {/* Nome utente */}
        <Box mt={4}>
          <Text fontSize="md" color="gray.500">
            {t("receipt.preview.name")}
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {receiptPreviewData.name}
          </Text>
        </Box>

        {/* Costo ore */}
        <Flex mt={4} justifyContent="space-between">
          <Box>
            <Text fontSize="md" color="gray.500">
              {t("receipt.preview.to_pay")}
            </Text>
            <Text fontSize="lg" fontWeight="bold">
              {receiptPreviewData.itemCount} ore tennis
            </Text>
          </Box>

          <Box>
            <Text fontSize="md" color="gray.500">
              {t("receipt.preview.item_price")}
            </Text>
            <Text fontSize="lg" fontWeight="bold" textAlign="right">
              €{receiptPreviewData.itemPrice.toFixed(2)}
            </Text>
          </Box>
        </Flex>
        <Divider mt={4} />

        {/* Totale da pagare */}
        <Flex justifyContent="space-between" mt={4}>
          <Text fontSize="md" color="gray.500">
            {t("receipt.preview.total")}
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            €{totalToPay.toFixed(2)}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ReceiptPreview;
