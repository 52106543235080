import { Navigate, Outlet } from "react-router-dom";
import { UserRole } from "../api/types";
import useAuthStore from "../store/auth";

type ProtectedRoutesProps = {
  roles?: UserRole[];
};

const ProtectedRoutes = ({ roles }: ProtectedRoutesProps) => {
  const [isAuthenticated, user] = useAuthStore(state => [state.isAuthenticated, state.user]);
  // wait until we know if the user is authenticated or not
  if (isAuthenticated === null) {
    return null;
  }

  if (!user || (roles && !roles.includes(user?.role))) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
