import { ChakraProvider } from "@chakra-ui/react";
import type { ReactElement } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { UserRole } from "./api/types";
import useInitApp from "./hooks/useInitApp";
import ActivationPage from "./pages/auth/activation";
import ForgotPasswordPage from "./pages/auth/forgot-password";
import ResetPasswordPage from "./pages/auth/reset-password";
import SignupPage from "./pages/auth/signup";
import BookingPage from "./pages/booking";
import HomePage from "./pages/home";
import NotFoundPage from "./pages/not-found";
import ProfilePage from "./pages/profile";
import ReceiptPage from "./pages/receipt";
import ReceiptGeneratePage from "./pages/receipt-generate";
import ProtectedRoutes from "./utils/protected-routes";

const App = (): ReactElement => {
  useInitApp();

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signup/activation" element={<ActivationPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/booking" element={<BookingPage />} />

          {/* Authenticated routes */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/profile" element={<ProfilePage />} />
          </Route>

          {/* Admin routes */}
          <Route element={<ProtectedRoutes roles={[UserRole.ADMIN]} />}>
            <Route path="/receipt" element={<ReceiptGeneratePage />} />
            <Route path="/receipt/:receiptId" element={<ReceiptPage />} />
          </Route>

          {/* Not found */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
