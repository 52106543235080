import { useEffect, useState } from "react";
import api from "../../../api";
import { HttpError, Receipt } from "../../../api/types";

/**
 * Custom hook to fetch a receipt from backend.
 * @returns {object} - Contains receipt, loading state, error state, and refetch function.
 */
function useFetchReceipt(receiptId: number) {
  const [receipt, setReceipt] = useState<Receipt | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<HttpError | null>(null);

  const fetchReceipt = async (receiptId: number) => {
    setLoading(true);
    setError(null);

    try {
      const receipt = await api.getReceipt(receiptId);

      if (api.error) {
        setError(api.error);
      }

      setReceipt(receipt);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReceipt(receiptId);
  }, []);

  return {
    receipt,
    loading,
    error,
    refetchReceipt: fetchReceipt,
  };
}

export default useFetchReceipt;
