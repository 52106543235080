import create, { SetState } from "zustand";
import { User } from "../api/types";

type AuthStore = {
  isAuthenticated: boolean | null; // default is null to indicate that we don't know yet
  user: User | null;
  setUser: (user: User | null) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

const useAuthStore = create<AuthStore>((set: SetState<AuthStore>) => ({
  isAuthenticated: null,
  user: null,
  setUser: (user: User | null) => set({ user }),
  setIsAuthenticated: (isAuthenticated: boolean) => set({ isAuthenticated }),
}));

export default useAuthStore;
