import {
  Alert,
  AlertDescription,
  AlertIcon,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api from "../../../api";
import { ACCESS_TOKEN_KEY } from "../../../constants";
import useAuthStore from "../../../store/auth";
import PasswordInput from "../../ui/password-input/password-input";

type LoginFormData = {
  username: string;
  password: string;
};

type LoginFormProps = {
  closeModal: () => void;
};

const LoginForm = ({ closeModal }: LoginFormProps): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>();

  const [showError, setShowError] = useState(false);
  const [setUser] = useAuthStore(state => [state.setUser]);

  const onSubmit = async (data: LoginFormData) => {
    const authToken = await api.login(data.username, data.password);
    if (!authToken) {
      setShowError(true);
      return;
    }

    // store access token and set user
    localStorage.setItem(ACCESS_TOKEN_KEY, authToken.access_token);

    const user = await api.getCurrentUser();
    if (!user) {
      setShowError(true);
      return;
    }
    setUser(user);
    closeModal();
  };

  return (
    <form id="login-form" onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* error */}
      {showError && (
        <Alert status="error" mb="0.5rem">
          <AlertIcon />
          <AlertDescription>{t("login.invalid_credentials")}</AlertDescription>
        </Alert>
      )}

      {/* username */}
      <FormControl isRequired isInvalid={!!errors.username}>
        <FormLabel htmlFor="username">Username</FormLabel>
        <Input
          type="text"
          id="username"
          {...register("username", { required: "username is required" })}
        />
      </FormControl>

      {/* password */}
      <FormControl mt={4} isRequired isInvalid={!!errors.password}>
        <FormLabel htmlFor="password">Password</FormLabel>
        <PasswordInput
          id="password"
          {...register("password", { required: "password is required" })}
        />
      </FormControl>
    </form>
  );
};

export default LoginForm;
