import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api from "../../../api";
import useShowToast from "../../../hooks/useShowToast";

type ForgotPasswordFormData = {
  email: string;
};

function ForgotPasswordForm() {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<ForgotPasswordFormData>();
  const { errorToast } = useShowToast();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data: ForgotPasswordFormData) => {
    setSuccess(false);

    await api.requestResetPassword(data.email);
    if (api.error) {
      console.error(api.error);
      errorToast({
        title: t("forgot_password.error.title"),
        description: t("forgot_password.error.message"),
      });
      return;
    }

    setSuccess(true);
  };

  const resetSuccess = () => {
    setSuccess(false);
  };

  return (
    <Box
      maxW="lg"
      mx="auto"
      mt="8"
      p={8}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="lg"
      bg="white"
    >
      <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb="4">
        {t("forgot_password.title")}
      </Text>
      <Text fontSize="sm" color="gray.600" textAlign="center" mb="6">
        {t("forgot_password.message")}
      </Text>

      <form id="forgot-password-form" onSubmit={handleSubmit(onSubmit)} onChange={resetSuccess}>
        <VStack spacing="4">
          <FormControl id="email" isRequired>
            <FormLabel> {t("forgot_password.email")}</FormLabel>
            <Input
              type="email"
              placeholder={t("forgot_password.placeholder.email")}
              {...register("email", { required: "email is required" })}
            />
          </FormControl>

          {!success && (
            <Button
              backgroundColor="gray.600"
              color="white"
              _hover={{ backgroundColor: "gray.700" }}
              width="full"
              type="submit"
            >
              {t("forgot_password.send_email")}
            </Button>
          )}

          {success && (
            <Alert status="success" mb="0.5rem">
              <AlertIcon />
              <AlertDescription className="linebreak">
                {t("forgot_password.success")}
              </AlertDescription>
            </Alert>
          )}
        </VStack>
      </form>
    </Box>
  );
}

export default ForgotPasswordForm;
