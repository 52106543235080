import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../api";
import ActivationFailed from "../../components/auth/signup/activation-failed";
import ActivationSuccesful from "../../components/auth/signup/activation-successful";
import HeaderCompact from "../../components/header/header-compact";

const ActivationPage = () => {
  const [searchParams] = useSearchParams();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const activateUser = async () => {
      const username = searchParams.get("user");
      const authCode = searchParams.get("code");

      if (!username || !authCode) {
        setHasError(true);
        return;
      }

      // activate user
      await api.activate(username, authCode);
      if (api.error) {
        setHasError(true);
      }
    };

    activateUser();
  }, []);

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <HeaderCompact />

      {/* Success Message */}
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" bg="gray.50">
        {!hasError && <ActivationSuccesful />}
        {hasError && <ActivationFailed />}
      </Box>
    </Box>
  );
};

export default ActivationPage;
