// local storage keys
export const ACCESS_TOKEN_KEY = "accessToken";

// footer contact details
export const CONTACT_EMAIL = "promotct@gmail.com";
export const CONTACT_PHONE = "324 0422099";
export const CONTACT_WEB = "www.tennistrebaseleghe.it";

// users
export const PASSWORD_MIN_LENGTH = 6;
