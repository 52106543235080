import ForgotPasswordForm from "../../components/auth/forgot-password/forgot-password-form";
import HeaderCompact from "../../components/header/header-compact";

function ForgotPasswordPage() {
  return (
    <>
      <HeaderCompact />
      <ForgotPasswordForm />;
    </>
  );
}

export default ForgotPasswordPage;
