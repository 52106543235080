import { Button, Text, useDisclosure } from "@chakra-ui/react";
import {
  Booking,
  BookingStatus,
  BookingType,
  Court,
  Hour,
  UserRole,
} from "../../api/types";
import useAuthStore from "../../store/auth";
import usePaymentStore from "../../store/payment";
import BookingEditModal from "./booking-edit-modal";
import BookingModal from "./booking-modal";
import { CellColor } from "./types";

type BookingCellProps = {
  date: Date;
  hour: Hour;
  court: Court;
  booking?: Booking;
  onBookingCompleted?: () => void;
  onBookingEditCompleted?: () => void;
};

const BookingCell = ({
  date,
  hour,
  court,
  booking,
  onBookingCompleted = () => null,
  onBookingEditCompleted = () => null,
}: BookingCellProps) => {
  const [user] = useAuthStore(state => [state.user]);
  const [bookingsToPay] = usePaymentStore(state => [state.bookingsToPay]);

  const {
    isOpen: isBookingModalOpen,
    onOpen: onBookingModalOpen,
    onClose: onBookingModalClose,
  } = useDisclosure();

  const {
    isOpen: isBookingEditModalOpen,
    onOpen: onBookingEditModalOpen,
    onClose: onBookingEditModalClose,
  } = useDisclosure();

  const handleCellClick = () => {
    if (booking) {
      onBookingEditModalOpen();
    } else {
      onBookingModalOpen();
    }
  };

  const getBackgroundColor = () => {
    // Only admin users can see the colors
    if (!booking || user?.role !== UserRole.ADMIN) {
      return CellColor.DEFAULT;
    }

    if (bookingsToPay.find(b => b.id === booking.id)) {
      return CellColor.TO_PAY;
    }
    if (booking.type === BookingType.SERVICE) {
      return CellColor.SERVICE_HOUR;
    }
    if (booking.status === BookingStatus.BOOKED) {
      return CellColor.UNPAID;
    }
    if (booking.status === BookingStatus.PAID) {
      return CellColor.PAID;
    }

    return CellColor.DEFAULT;
  };

  /*
  Show only the booking name in case:
   - user is not logged in
   - booking does not belong to the user and user is not an admin
  */
  if (
    !user ||
    (booking && booking.user_id !== user.id && user.role !== UserRole.ADMIN)
  ) {
    return (
      <>
        <Text
          width="100%"
          height="30px"
          p="2"
          display="flex"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          fontSize="md"
        >
          {booking?.username}
        </Text>
      </>
    );
  }

  /*
  Allow actions if:
  - user is logged in and the booking belongs to the user
  - user is an admin
  */
  return (
    <>
      <Button
        width="100%"
        height="30px"
        p="2"
        borderRadius="0"
        fontSize="md"
        fontWeight="normal"
        colorScheme="gray"
        variant="ghost"
        size="xs"
        _hover={{ boxShadow: "lg", cursor: "pointer", bg: "gray.100" }}
        onClick={handleCellClick}
        backgroundColor={getBackgroundColor()}
      >
        {booking?.username}
      </Button>

      {/* Booking modal */}
      <BookingModal
        isOpen={isBookingModalOpen}
        onClose={onBookingModalClose}
        date={date}
        hour={hour}
        court={court}
        onBookingCompleted={onBookingCompleted}
      />

      {/* Edit Booking modal */}
      {booking && (
        <BookingEditModal
          isOpen={isBookingEditModalOpen}
          onClose={onBookingEditModalClose}
          booking={booking}
          onBookingEditCompleted={onBookingEditCompleted}
        />
      )}
    </>
  );
};

export default BookingCell;
