import create, { SetState } from "zustand";
import { Booking } from "../api/types";

type PaymentStore = {
  bookingsToPay: Booking[];
  setBookingsToPay: (bookings: Booking[]) => void;
  clearBookingsToPay: () => void;
};

const usePaymentStore = create<PaymentStore>((set: SetState<PaymentStore>) => ({
  bookingsToPay: [],
  setBookingsToPay: (bookingsToPay: Booking[]) => set(state => ({ bookingsToPay })),
  clearBookingsToPay: () => set({ bookingsToPay: [] }),
}));

export default usePaymentStore;
