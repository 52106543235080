import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api from "../../api";
import { UpdateUserRequest } from "../../api/types";
import useShowToast from "../../hooks/useShowToast";
import useValidatePassword from "../../hooks/useValidatePassword";
import PasswordInput from "../ui/password-input/password-input";

type EditPasswordFormData = {
  password: string;
  confirmPassword: string;
};

const EditPasswordForm = (): ReactElement => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm<EditPasswordFormData>();
  const { successToast, errorToast } = useShowToast();
  const { isPasswordValid, passwordError, resetPasswordError } = useValidatePassword();

  const onSubmit = async (data: EditPasswordFormData) => {
    // validate password
    if (!isPasswordValid(data.password, data.confirmPassword)) {
      return;
    }

    // update user
    const request: UpdateUserRequest = { password: data.password };
    await api.updateUser(request);
    if (api.error) {
      errorToast({
        title: t("profile.password_update_error_title"),
        description: t("profile.password_update_error_message"),
      });
      return;
    }

    reset();
    successToast({
      title: t("profile.password_update_success_title"),
      description: t("profile.password_update_success_message"),
    });
  };

  return (
    <form id="edit-password-form" onSubmit={handleSubmit(onSubmit)} onChange={resetPasswordError}>
      <VStack spacing={4} align="flex-start" mt={8}>
        <FormControl>
          <FormLabel>{t("profile.password")}</FormLabel>
          <PasswordInput
            placeholder={t("profile.placeholder.password")}
            id="password"
            {...register("password")}
          />
        </FormControl>

        <FormControl>
          <PasswordInput
            placeholder={t("profile.placeholder.confirm_password")}
            id="confirmPassword"
            {...register("confirmPassword")}
          />
        </FormControl>

        {/* errors */}
        {passwordError && (
          <Alert status="error" mb="0.5rem">
            <AlertIcon />
            <AlertDescription>{passwordError.message}</AlertDescription>
          </Alert>
        )}

        <Button
          backgroundColor="gray.600"
          color="white"
          _hover={{ backgroundColor: "gray.700" }}
          size="lg"
          width="full"
          mt={4}
          alignSelf="center"
          form="edit-password-form"
          type="submit"
        >
          {t("profile.update_password_button")}
        </Button>
      </VStack>
    </form>
  );
};

export default EditPasswordForm;
