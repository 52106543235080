import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetchReceipt from "../components/receipt/hooks/useFetchReceipt";
import ReceiptActions from "../components/receipt/receipt-actions";
import ReceiptPreview from "../components/receipt/receipt-preview";
import { ReceiptData } from "../components/receipt/types";
import { parseToIntOrNull } from "../utils/numbers";

type ReceiptPageParams = {
  receiptId?: string;
};

const ReceiptPage = () => {
  const [data, setData] = useState<ReceiptData | undefined>(undefined);
  const params = useParams<ReceiptPageParams>();
  const receiptId = parseToIntOrNull(params.receiptId);
  const { receipt } = useFetchReceipt(receiptId!); // TODO check not exists

  const print = () => {
    setTimeout(() => {
      window.print();
    }, 0);
  };

  useEffect(() => {
    if (receipt) {
      setData({
        number: receipt.number,
        name: receipt.name,
        itemCount: receipt.item_count,
        itemPrice: receipt.item_price,
      });
      print();
    }
  }, [receipt]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ReceiptPreview data={data} readonly />
      <ReceiptActions data={data} mode="view" />
    </>
  );
};

export default ReceiptPage;
