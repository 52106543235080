import { Button, Flex, Heading, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Price } from "../../api/types";
import useFetchPrices from "./hooks/useFetchPrices";

type PriceListProps = {
  onSelect?: (price: Price) => void;
};

const PriceList = ({ onSelect = () => {} }: PriceListProps) => {
  const { t } = useTranslation();
  const { prices } = useFetchPrices();

  return (
    <Flex maxWidth="4xl" mx="auto" mt={8} mb={8} direction="column">
      <Heading as="h3" size="md" mb={4}>
        {t("price_list.title")}
      </Heading>
      <Table size="xs">
        <Thead>
          <Tr>
            <Th>{t("price_list.description")}</Th>
            <Th>{t("price_list.price")}</Th>
            <Th>{t("price_list.select")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prices?.map(price => (
            <Tr key={price.id} _hover={{ boxShadow: "md", cursor: "pointer", bg: "gray.100" }}>
              <Td>{price.description}</Td>
              <Td width="100px" textAlign="right" pr={8}>
                {price.value} €
              </Td>
              <Td width="100px">
                <Button colorScheme="blue" size="xs" onClick={() => onSelect(price)}>
                  {t("price_list.select")}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default PriceList;
