import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import BookingFilters from "../components/booking/booking-filters";
import BookingPaymentBar from "../components/booking/booking-payment-bar";
import BookingTable from "../components/booking/booking-table";
import { Filters } from "../components/booking/types";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";

const BookingPage = () => {
  const [filters, setFilters] = useState<Filters | undefined>(undefined);

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
  };

  return (
    <>
      <Flex direction="column" minHeight="100vh">
        <Flex flex="1" direction="column">
          <Header />
          <BookingFilters onFilterChange={handleFilterChange} />
          {filters && <BookingTable filters={filters} />}
          <BookingPaymentBar />
        </Flex>
        <Footer />
      </Flex>
    </>
  );
};

export default BookingPage;
