import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useValidateResetCode from "../../components/auth/forgot-password/hooks/useValidateResetCode";
import ResetPasswordForm from "../../components/auth/forgot-password/reset-password-form";
import ResetPasswordInvalidLink from "../../components/auth/forgot-password/reset-password-invalid-link";
import HeaderCompact from "../../components/header/header-compact";

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const { isResetCodeValid } = useValidateResetCode();
  const [isValid, setIsValid] = useState(true);

  const username = searchParams.get("user");
  const code = searchParams.get("code");

  useEffect(() => {
    const validate = async () => {
      setIsValid(true);

      if (!username || !code) {
        setIsValid(false);
        return;
      }

      const isCodeValid = await isResetCodeValid(username, code);
      if (!isCodeValid) {
        setIsValid(false);
      }
    };

    validate();
  }, [username, code]);

  if (!username || !code || !isValid) {
    return (
      <>
        <HeaderCompact />
        <ResetPasswordInvalidLink />
      </>
    );
  }

  return (
    <>
      <HeaderCompact />
      <ResetPasswordForm code={code} username={username} />;
    </>
  );
};

export default ResetPasswordPage;
